<template>
  <a-modal
    v-model="visible"
    width="500px"
    :title="modalTitle"
    :confirmLoading="confirmLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <a-form-model :model="formModel" :rules="rules" ref="form" :label-col="{ span: 6 }" :wrapper-col="{ span: 17 }">
      <a-form-model-item label="续签时间" prop="number" v-if="!!ids.length">
        <a-select :options="yearList" style="width: 100%" v-model="formModel.number" placeholder="续签时间">
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="续签时间" prop="date" v-else>
        <a-range-picker
          value-format="YYYY-MM-DD"
          v-model="formModel.date"
          allowClear
          style="width: 100%"
        />
      </a-form-model-item>
    </a-form-model>
  </a-modal>
</template>

<script>
import { labourBatch } from '@/api/workbench'
import { createContract } from '@/api/contract'

import moment from 'moment'
export default {
  data () {
    return {
      formModel: {
        date: [],
        number: 12
      },
      confirmLoading: false,
      ids: [],
      rowItem: {},
      visible: false,
      modalTitle: '',
      yearList: [],
      rules: {
        number: [{ required: true, message: '请选择续签时间', trigger: 'change' }],
        date: [{ required: true, message: '请选择续签时间', trigger: 'blur' }]
      }
    }
  },
  created () {
    for (let index = 1; index <= 12; index++) {
      this.yearList.push({
        value: index,
        label: `${index}个月`
      })
    }
  },
  methods: {
    open (isAll, data) {
      if (isAll) {
        this.ids = [...data]
        this.modalTitle = '批量续签劳务合同时间'
        this.formModel.number = 12
      } else {
        this.rowItem = { ...data }
        this.modalTitle = '续签劳务合同时间编辑'
        const { endTime } = data
        if (endTime) {
          this.formModel.date = [moment(endTime).add(1, 'd'), moment(endTime).add(1, 'y')]
        } else {
          this.formModel.date = []
        }
      }
      this.visible = true
    },
    disabledDate (curDate) {
      if (this.ids.length) {
        return false
      } else {
        const endTime = this.rowItem.endTime
        return curDate && endTime && moment(curDate).isBefore(moment(endTime).add(1, 'd'))
      }
    },
    handelGetModel () {
      if (this.ids.length) {
        return this.ids.map(item => ({
            signId: item,
            plusMonth: this.formModel.number
        }))
      } else {
        const [startTime, endTime] = this.formModel.date || []
        return {
          contractNumber: 'LWHT',
          post: this.rowItem.positionName,
          projectEndTime: this.formatTime(this.rowItem.projectEndTime, 'YYYY-MM-DD'),
          projectId: this.rowItem.projectId,
          demandId: this.rowItem.demandId,
        //   projectLeaderId: this.rowItem.projectLeaderId,
          projectName: this.rowItem.projectName,
          projectStartTime: this.formatTime(this.rowItem.projectStartTime, 'YYYY-MM-DD'),
          userId: this.rowItem.userId,
          workerName: this.rowItem.realName,
        //   money: this.rowItem.salary,
          status: 0,
          enterpriseId: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseId,
          enterpriseName: this.$store.getters.userInfo.enterpriseInfoDto.enterpriseName,
          startTime,
          endTime
        }
      }
    },
    handleSubmit () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.confirmLoading = true
          const fun = this.ids.length ? labourBatch : createContract
          fun(this.handelGetModel())
            .then(() => {
              this.$message.success('续签成功')
              this.handleCancel()
              this.$emit('success')
            })
            .finally(() => {
              this.confirmLoading = false
            })
        }
      })
    },
    handleCancel () {
      this.rowItem = {}
      this.ids = []
      this.$refs.form.resetFields()
      this.visible = false
    }
  }
}
</script>
