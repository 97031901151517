<template>
  <a-drawer
    title="项目人员"
    placement="right"
    :destroyOnClose="true"
    :width="1200"
    :visible="visible"
    @close="onClose">
    <a-form-model layout="inline" :model="form" style="margin-bottom: 12px">
      <a-form-model-item label="姓名：">
        <a-input v-model="form.realName" placeholder="请输入姓名" />
      </a-form-model-item>
      <a-form-model-item style="float: right; margin-right: 0" v-perms="'search'">
        <a-button type="" @click="form = {}"> 重置 </a-button>
        <a-button type="primary" @click="$refs.table.refresh()"> 搜索 </a-button>
      </a-form-model-item>
    </a-form-model>
    <p-table
      ref="table"
      row-key="id"
      extraHeight="320"
      :sourceData="getDataApi"
      :columns="columns"
      :rowSelection="rowSelection"
    >
      <template slot="headerLeft">
        <div v-perms:user="'contract-all'">
          <a-button type="primary" @click="handleAll" :disabled="!selectedRowKeys.length">批量续签</a-button>
          <span style="color: #E40F0F; margin-left: 8px;">*部分数据因版本原因无法进行批量签署</span>
        </div>
      </template>
      <template slot="action" slot-scope="text, record">
        <a-button type="link" @click="handleOpen(record)" v-perms:user="'contract'">续签合同</a-button>
        <a-button type="link" @click="handleOpenContract(record)" v-perms:user="'contract-list'">合同列表</a-button>
      </template>
    </p-table>
    <vform ref="vform" />
    <contractModal ref="contractModal"/>
  </a-drawer>
</template>

<script>
import vform from './form.vue'
import moment from 'moment'
import { userSignList } from '@/api/workbench'
import contractModal from './contract-modal.vue'
export default {
  name: 'Details',
  components: {
    vform,
    contractModal
  },
  data () {
    return {
      visible: false,
      form: {},
      rowItem: {},
      selectedRowKeys: []
    }
  },
  computed: {
    rowSelection () {
      const { selectedRowKeys } = this
      return {
        columnWidth: '60',
        selectedRowKeys,
        onChange: this.handleTableSelectChange,
        getCheckboxProps: (r) => ({
          props: {
            disabled: !r.startTime || !r.endTime
          }
        })
      }
    },
    columns () {
      return [
        {
          title: '序号',
          dataIndex: 'index',
          key: 'index',
          width: 60,
          customRender: (t, r, i) => i + 1
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          key: 'realName'
        },
        {
          title: '工种/专业',
          dataIndex: 'positionName',
          key: 'positionName'
        },
        {
          title: '性别',
          dataIndex: 'sex',
          key: 'sex',
          customRender: (t) => (t == 1 ? '女' : '男')
        },
        {
          title: '年龄',
          dataIndex: 'age',
          key: 'age'
        },
        {
          title: '上次签订合同时间',
          dataIndex: 'contractSignDate',
          key: 'contractSignDate',
          width: 180,
          customRender: (t) => t && moment(t).format('YYYY-MM-DD')
        },
        // {
        //   title: '合同状态',
        //   dataIndex: 'contractStatus',
        //   key: 'contractStatus',
        //   customRender: (t) => t && moment(t).format('YYYY-MM-DD')
        // },
        {
          title: '用工状态',
          dataIndex: 'demandStatus',
          key: 'demandStatus',
          customRender: (t) => (t == 1 ? '进行中' : '任务终止')
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 220,
          key: 'action',
          scopedSlots: { customRender: 'action' }
        }
      ]
    }
  },
  methods: {
    show (rowItem) {
      this.rowItem = { ...rowItem }
      this.visible = true
    },
    onClose () {
      this.rowItem = {}
      this.selectedRowKeys = []
      this.visible = false
    },
    handleTableSelectChange (selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    getDataApi (params) {
      this.selectedRowKeys = []
      if (this.rowItem.projectId) {
        return userSignList({
          ...this.form,
          ...params,
          projectId: this.rowItem.projectId
        })
      } else {
        return Promise.resolve()
      }
    },
    handleOpen (rowItem) {
      this.$refs.vform.open(false, rowItem)
    },
    handleOpenContract (rowItem) {
      this.$refs.contractModal.show(rowItem)
    },
    handleAll () {
      this.$refs.vform.open(true, this.selectedRowKeys)
    }
  }
}
</script>
