import request from '@/utils/request'

/**
 * 分页查询合同
 * @param {*} params
 * @returns
 */
export function getContract (params) {
    return request.get('/api/enterprise/contract/page', {
        params: {
            ...params
        }
    })
        .then(response => response)
}

/**
 * 分页查询合同(企业用人合同)
 * @param {*} params
 * @returns
 */
export function getContractByEnter (params) {
    return request.get('/api/plat/employmentContract/platContractController/page', {
        params: {
            ...params
        }
    })
        .then(response => response)
}

/**
 * 生成签订合同
 * @param {*} model
 * @returns
 */
export function createContract (model) {
    return request.post('/api/enterprise/contract/labour', model)
        .then(response => response)
}

/**
 * 生成企业采购合同
 * @param {*} model
 * @returns
 */
export function createAndPreviewEnterprisePurchaseContract (model) {
  return request.post('/api/enterprise/contract/purchase', model)
    .then(response => response)
}

/**
 * 更改合同状态,签订合同
 * @param {*} model
 * @returns
 */
export function updateStatus (model) {
    return request.put('/api/enterprise/contract/updateStatus', model)
        .then(response => response)
}

export function signEnterprisePurchaseContract (model) {
  return request.put('/api/enterprise/contract/purchase/sign', model)
    .then(response => response)
}
/**
 * 查询合同详情
 * @param {*} id
 * @returns
 */
export function getContractById (id) {
    return request.get(`/api/enterprise/contract/${id}`)
        .then(response => response)
}
